import React from "react"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"

import { theme } from "../../styles/theme"

const Caveats = ({ location }) => {
  return (
    <Layout>
      <SEO title="Blood Components Compatibility" />

      <CustomNavTitle
        title="Blood Components Compatibility"
        destination="/compatibility/results"
        location={location}
      />

      <article className="mt-gap-2" css={theme.components.row}>
        <dl className="mb-gap-2 max-w-content">
          <div css={styles.caveat}>
            <dt css={styles.caveatName}>*</dt>
            <dd css={styles.caveatDesc}>
              If D negative platelets are unavailable, D positive units may be
              transfused. In the case of women of child bearing potential,
              anti-D prophylaxis should also be given. Other patient groups do
              not require anti-D.
            </dd>
          </div>
          <div css={styles.caveat}>
            <dt css={styles.caveatName}>$</dt>
            <dd css={styles.caveatDesc}>
              High titre (HT) negative anti-A and/or anti-B should be selected
              where available, lowering the risk of haemolysis.
            </dd>
          </div>
          <div css={styles.caveat}>
            <dt css={styles.caveatName}>%</dt>
            <dd css={styles.caveatDesc}>
              Only suitable for emergency use, unless confirmed high titre (HT)
              negative.
            </dd>
          </div>
        </dl>
      </article>
    </Layout>
  )
}

const styles = {
  caveat: css({
    margin: `0 0 ${theme.gap} 0`,
    padding: "none",
    display: "flex",
  }),
  caveatName: css({
    flexShrink: 0,
    marginRight: theme.gap,
    fontWeight: "bold",
  }),
}

export default Caveats
